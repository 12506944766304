import request from './request';

// authenticate the user
// spreadsheetId "example" will request single file gdrive permissions
// spreadsheetId null will not request an gdrive permissions
// spreadsheetId anything else will request permission for all spreadsheets
const authenticate = async spreadsheetId => {
  const response = await request('getGDurl', {
    spreadsheetId
  });
  const responseJSON = await response.json();

  if (typeof window !== 'undefined') {
    let url = responseJSON.url;

    // if we got no url, we probably have already given permissions
    // and the error was "Requested entity not found" aka invalid spreadsheetId
    // that applies only if there is a spreadsheetId given (not null)
    if (spreadsheetId && !url) {
      url = '/create?error=invalidSpreadsheetId';
    }

    window.location.replace(url);
  }
};

export default authenticate;
