import React, {useContext, useState} from 'react';
import {Link, navigate} from 'gatsby';
import PropTypes from 'prop-types';
import UserContext from '../modules/UserContext';
import LoadingContext from '../modules/LoadingContext';
import authenticate from '../modules/authenticate';
import request from '../modules/request';
import logo from '../images/logo.svg';
import {Wrapper, Title, Logo, Name, Menu, MenuItem, Hamburger} from './Header.styles';

const Header = ({siteTitle}) => {
  const user = useContext(UserContext);
  const loading = useContext(LoadingContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const logout = async () => {
    await request('user/logout');

    // make sure we reload/navigate the user to call userinfo request
    if (typeof window !== 'undefined') {
      if (window.location.pathname === '/') {
        window.location.reload();
      } else {
        navigate('/');
      }
    }
  };

  return (
    <Wrapper>
      <Title>
        <Link to="/">
          <Logo src={logo} />
          <Name>{siteTitle}</Name>
        </Link>
      </Title>
      <Menu hide={!isMenuOpen}>
        <MenuItem onClick={() => setIsMenuOpen(false)}>
          <Link to="/#features">Features</Link>
        </MenuItem>
        <MenuItem onClick={() => setIsMenuOpen(false)}>
          <a href="/#pricing">Pricing</a>
        </MenuItem>
        <MenuItem>
          <a href="/documentation/">Docs</a>
        </MenuItem>
        <MenuItem>
          <a href="/contact/">Support</a>
        </MenuItem>
        {!loading &&
          (user.email ? (
            <>
              <MenuItem>
                <Link to="/dashboard/">Dashboard</Link>
              </MenuItem>
              <MenuItem onClick={logout}>
                <a href="#logout">Logout</a>
              </MenuItem>
            </>
          ) : (
            <MenuItem onClick={() => authenticate(null)}>
              <a href="#authenticate">Login/Register</a>
            </MenuItem>
          ))}
      </Menu>
      <Hamburger
        className={`hamburger hamburger--elastic ${isMenuOpen && 'is-active'}`}
        type="button"
        onClick={handleHamburgerClick}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </Hamburger>
    </Wrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired
};

export default Header;
